@import 'vars.less';

body {
  background-color: @color-primary;
  color: @text-color;
  line-height: 1.75;
  letter-spacing: 1px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: @text-size;
}

a {
  color: @text-color;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: @text-color;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: @title-text-color;
  font-weight: 400;

  a {
    color: @title-text-color;
  }
}

header {
  text-align: center;
  margin-bottom: 10px;
  padding-top: 24px;
  color: @header-text-color;

  h1 {
    font-size: @header-text-size;
    color: @header-text-color;
  }

  hr {
    border-color: @header-text-color;
  }

  .title {
    letter-spacing: 3px;

    a {
      text-decoration: none;
    }
  }

  .position, .location {
    font-size: 12px;
  }

  .btn {
    color: @social-icon-color;

    &:hover {
      color: @social-icon-color-hover;
    }
  }
}

article {
  margin-bottom: 40px;

  img { max-width: 100%; }

  &.project {
    [class^="col-"] {
      position: static;
    }

    .title { color: @text-color; }

    .company {
      a { color: @title-text-color; }
    }

    .date-range {
      display: block;
      padding-bottom: 5px;

      .fa { padding-right: 5px; }
    }

    .photo { margin-bottom: 10px; }
  }
}

hr {
  width: 20px;
  border: 1px solid @title-text-color;
  margin: 10px auto;
  border-radius: 1px;
}

.main-content-wrapper {
  background-color: @color-white;
  padding: 15px;
}

.main-content {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.primary-section {
  h2 {
    text-align: center;
    font-size: @header-text-size;
  }

  margin-bottom: 32px;
}

footer {
  padding: 15px;
  color: @color-white;
  text-align: center;

  a {
    color: @color-white;
    text-decoration: none;

    &:hover {
      color: @color-white;
      text-decoration: none;
    }
  }
}


.badge {
  background-color: @color-primary;
  color: @color-white;
  padding:8px;
  margin-bottom: 8px;

  a& {
    text-decoration: none;
  }
}

.img-circle {
  border-radius: 50%;
}

.btn-cta {
  display: block;
  margin-top: 16px;
  padding: 8px;
  transition: transform 200ms;
  transform: scale(1);
  border-radius: 0;
  background-color: @color-primary;
  color: @color-white;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    color: @color-white;
    transform: scale(1.1);
  }

}

.btn-link {
  text-decoration: none;
}

.contact-form {
  max-width: 450px;
}